@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

* {
  font-family: 'Inter';
  margin: 0;
  text-decoration: none;
}

html, body, #root {
  height: 100%;
  width: 100%;
}

.center {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.center>* {
  display: block;
  margin: auto;
  text-align: center;
}

.verticalCenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.noSelect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
textarea {
  outline: none;
  resize: none;
  font-family: "Roboto";
  border: none;
  -moz-appearance: textfield; 
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: 0px;
  font-family: 'Roboto';
  text-align: center;
}
